/* eslint-disable no-console */
import app from '../app.js';

let preferences = null;

const KEY = 'RiskAliveUserPreferences';

export const init = (data) => {
  preferences = data || {};

  if (app === 'Lorax') {
    if (data) {
      localStorage.setItem(KEY, JSON.stringify(preferences));
    } else {
      localStorage.removeItem(KEY);
    }
  } else {
    if (data) {
      sessionStorage.setItem(KEY, JSON.stringify(preferences));
    } else {
      sessionStorage.removeItem(KEY);
    }
  }
};

export const get = (key, defaultValue) => {
  if (preferences === null) {
    const local = localStorage.getItem(KEY);
    if (local) {
      preferences = JSON.parse(local);
    }

    const session = sessionStorage.getItem(KEY);
    if (session) {
      preferences = JSON.parse(session);
    }
  }

  if (preferences[key] === undefined || preferences[key] === null) {
    return defaultValue;
  }

  return preferences[key];
};
