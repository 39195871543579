import { lorax } from '../urls';
import { decode } from './token';

let nextCompanyId = null;

export default async (companyId) => {
  if (companyId === nextCompanyId) {
    console.debug('[SSO] Wrong company: Redirect ongoing...');
    return;
  }

  console.debug('[SSO] Wrong company: Called.');
  const token = await decode();
  if (!token || token.CompanyId === companyId) {
    console.debug('[SSO] Wrong company: Matched.');
    return;
  }

  console.debug('[SSO] Wrong company: Redirecting.');
  nextCompanyId = companyId;
  window.location.href = `${lorax}/account/change-company?company-id=${companyId}&redirect=${encodeURIComponent(window.location.href)}`;
};
